<template>
  <v-content class="login-view">
    <v-row
      justify="center"
      class="login fill-height"
    >
      <transition name="fade">
        <v-col
          cols="12"
          md="5"
          lg="4"
          xl="3"
        >
          <v-img
            :src="require('@/assets/img/logo_full.png')"
            width="200"
            class="login__logo"
            contain
          />

          <v-form
            ref="form"
            v-model="valid"
            class="login__card elevation-1 pa-6 mt-12"
            lazy-validation
            @submit="login"
          >
            <StInputComponent
              id="username"
              ref="username"
              v-model="username"
              autocomplete="username"
              component="v-text-field"
              prepend-icon="mdi-account"
              name="login"
              :rules="emailRules"
              :label="$t('Login.email')"
              type="text"
              :trim="true"
              :disabled="loading"
              required
              dark
            />
            <StInputComponent
              id="password"
              v-model="password"
              component="v-text-field"
              autocomplete="password"
              prepend-icon="mdi-lock"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              name="password"
              :label="$t('Login.password')"
              :type="showPass ? 'text' : 'password'"
              required
              :disabled="loading"
              dark
              @click:append="showPass = !showPass"
            />

            <v-row
              justify="end"
            >
              <v-btn
                color="primary"
                class="mx-0 mt-2"
                :loading="loading"
                rounded
                large
                @click="login"
              >
                {{ $t('Login.enter') }}
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </transition>
    </v-row>
  </v-content>
</template>

<script>
import StInputComponent from '@stilingue/st-input-component';
import { has } from '@/plugins/lodash';
import { mapActions } from 'vuex';
import { ENTER_KEY_CODE } from '@/utils/constants';
import { requiredRule, email } from '@/utils/form_rules';
import { shutdownIntercom } from '@/plugins/zendesk';

export default {
  name: 'Login',
  components: {
    StInputComponent
  },
  data() {
    return {
      showLoginForm: false,
      loading: false,
      valid: false,
      showPass: false,
      username: '',
      password: '',
      emailRules: [
        requiredRule(this.$t('FormRules.emailRules.required')),
        email(this.$t('FormRules.emailRules.invalid'))
      ],
      passwordRules: [
        requiredRule(this.$t('FormRules.passwordRules.required'))
      ],
      genericErrorMsg: this.$t('Login.genericErrorMsg'),
      specificErrors: {
        'Not Found': this.$t('Login.notFound'),
        'Unauthorized': this.$t('Login.Unauthorized'),
        'No Token Received': this.$t('Login.genericErrorMsg')
      }
    };
  },
  created() {
    shutdownIntercom();
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyUp);
    if (has(this, '$refs.username.focus')) {
      this.$refs.username.focus();
    }
  },
  destroyed() {
    /* istanbul ignore next  */
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    ...mapActions('user', [
      'makeLoginRequest'
    ]),
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.showPass = false;

        this
          .makeLoginRequest({
            username: this.username,
            password: this.password
          })
          .catch(this.handleError)
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleError(response) {
      let errorMsg = this.genericErrorMsg;

      if (has(response, 'data.error') && response.data.error in this.specificErrors) {
        errorMsg = this.specificErrors[response.data.error];
      }

      this.$noty.error(errorMsg);

      return errorMsg;
    },
    handleKeyUp(event) {
      if (event.key === 'Enter' ||
        event.key === 'NumpadEnter' ||
        event.keyCode === ENTER_KEY_CODE) {
        this.login();
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .login {
    padding-top: 15vh;
  }

  .login-view {
    background-color: var(--gray-050);
  }

  .login__logo {
    margin: 0 auto 20px;
  }

  .login__card {
    background-color: var(--gray-100);
  }

  ::v-deep {
    @keyframes autofill {
      to {
        color: whitesmoke;
        background: transparent;
      }
    }

    input:-webkit-autofill {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
      animation-name: autofill;
      animation-fill-mode: both;
    }

    .v-input--is-disabled input:-webkit-autofill {
      -webkit-animation-name: autofill;
      -webkit-animation-fill-mode: both;
      animation-name: autofill;
      animation-fill-mode: both;
    }
  }

</style>

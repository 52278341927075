var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "login-view" },
    [
      _c(
        "v-row",
        { staticClass: "login fill-height", attrs: { justify: "center" } },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "5", lg: "4", xl: "3" } },
                [
                  _c("v-img", {
                    staticClass: "login__logo",
                    attrs: {
                      src: require("@/assets/img/logo_full.png"),
                      width: "200",
                      contain: ""
                    }
                  }),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "login__card elevation-1 pa-6 mt-12",
                      attrs: { "lazy-validation": "" },
                      on: { submit: _vm.login },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("StInputComponent", {
                        ref: "username",
                        attrs: {
                          id: "username",
                          autocomplete: "username",
                          component: "v-text-field",
                          "prepend-icon": "mdi-account",
                          name: "login",
                          rules: _vm.emailRules,
                          label: _vm.$t("Login.email"),
                          type: "text",
                          trim: true,
                          disabled: _vm.loading,
                          required: "",
                          dark: ""
                        },
                        model: {
                          value: _vm.username,
                          callback: function($$v) {
                            _vm.username = $$v
                          },
                          expression: "username"
                        }
                      }),
                      _c("StInputComponent", {
                        attrs: {
                          id: "password",
                          component: "v-text-field",
                          autocomplete: "password",
                          "prepend-icon": "mdi-lock",
                          "append-icon": _vm.showPass
                            ? "mdi-eye"
                            : "mdi-eye-off",
                          rules: _vm.passwordRules,
                          name: "password",
                          label: _vm.$t("Login.password"),
                          type: _vm.showPass ? "text" : "password",
                          required: "",
                          disabled: _vm.loading,
                          dark: ""
                        },
                        on: {
                          "click:append": function($event) {
                            _vm.showPass = !_vm.showPass
                          }
                        },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "v-row",
                        { attrs: { justify: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-0 mt-2",
                              attrs: {
                                color: "primary",
                                loading: _vm.loading,
                                rounded: "",
                                large: ""
                              },
                              on: { click: _vm.login }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("Login.enter")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }